/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";


/* https://fonts.google.com/icons?icon.size=24&icon.color=%235f6368&icon.set=Material+Icons */ /// mat Icons
html,
body {
  height: 100%;
  margin: 0;
  font-family: "BoeingMezo-Regular" !important;
}



.p-menubar .p-menubar-custom,
.p-menubar .p-menubar-end {
  margin-left: 1em !important;
}

@font-face {
  font-family: "BoeingMezo-Regular";
  src: url(assets/boeing/BoeingMezo-Regular.woff) format('woff'),
}

.p-component {
  font-family: "BoeingMezo-Regular" !important;
}

:root {
  --font-size-8: 0.4167vw;
  /* Equivalent to 8px */
  --font-size-9: 0.46875vw;
  /* Equivalent to 9px */
  --font-size-10: 0.5208vw;
  /* Equivalent to 10px */
  --font-size-11: 0.5729vw;
  /* Equivalent to 11px */
  --font-size-12: 0.625vw;
  /* Equivalent to 12px */
  --font-size-13: 0.6771vw;
  /* Equivalent to 13px */
  --font-size-14: 0.7292vw;
  /* Equivalent to 14px */
  --font-size-15: 0.78125vw;
  /* Equivalent to 15px */
  --font-size-16: 0.8333vw;
  /* Equivalent to 16px */
  --font-size-17: 0.8854vw;
  /* Equivalent to 17px */
  --font-size-18: 0.9375vw;
  /* Equivalent to 18px */
  --font-size-19: 0.9896vw;
  /* Equivalent to 19px */
  --font-size-20: 1.04167vw;
  /* Equivalent to 20px */
  --font-size-21: 1.09375vw;
  /* Equivalent to 21px */
  --font-size-22: 1.1458vw;
  /* Equivalent to 22px */
  --font-size-23: 1.1979vw;
  /* Equivalent to 23px */
  --font-size-24: 1.25vw;
  /* Equivalent to 24px */
  --font-size-25: 1.3021vw;
  /* Equivalent to 25px */
  --font-size-26: 1.3542vw;
  /* Equivalent to 26px */
  --font-size-27: 1.40625vw;
  /* Equivalent to 27px */
  --font-size-28: 1.4583vw;
  /* Equivalent to 28px */
  --font-size-29: 1.5104vw;
  /* Equivalent to 29px */
  --font-size-30: 1.5625vw;
  /* Equivalent to 30px */

  --width-10: 0.5208vw;
  /* Equivalent width in vw */
  --height-10: 0.9259vh;
  /* Equivalent height in vh */
}

// ::ng-deep .p-sortable-column-icon .p-element.p-icon-wrapper svg {
//   display: none;
// }

// ::ng-deep .p-sortable-column-icon .p-element.p-icon-wrapper {
//   font-family: 'primeicons';
// }

// ::ng-deep .p-sortable-column-icon sortamountdownicon.p-element.p-icon-wrapper:before {
//   content: '\e919';
// }

// ::ng-deep .p-sortable-column-icon sortamountupalticon.p-element.p-icon-wrapper:before {
//   content: '\e91c';
// }


// ::ng-deep .pi-sort-alt:before {
//   content: "\e930";
//   /* PrimeIcons character code for pi-chevron-down */
// }

// /* Override sort icon for ascending order */
// ::ng-deep .pi-sort-amount-up:before {
//   content: "\e930";
//   /* PrimeIcons character code for pi-chevron-down */
// }

// /* Override sort icon for descending order */
// ::ng-deep .pi-sort-amount-down:before {
//   content: "\e931";
//   /* PrimeIcons character code for pi-chevron-up */
// }

// ::ng-deep .pi-sort-alt:before {
//   content: '\e903 \A \e902';
//   }
//   ::ng-deep .pi-sort-amount-up-alt:before {
//   content: '\e903';
//   }
//   ::ng-deep .pi-sort-amount-down:before {
//   content: '\e902';
//   }